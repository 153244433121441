import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import ConsultationListItem from "./ConsultationListItem";
import ConsultationPagination from './ConsultationPagination';
import { getConsultationsAction, startLoader } from "../../../store/actions/ConsultationActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { Link, withRouter } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from '../DataTables/DataTableBase';
import { Modal } from 'react-bootstrap';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";

/* --- BEGIN ConsultationList
	@param(Object): props
    description: Listing des consultations
*/
export const ConsultationList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiaryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            doctorName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            providerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isHospitalisation: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: t('common.filter-by-code'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'beneficiaryName', headerName: t('common.patient'), filterPlaceholder: t('common.filter-by-patient'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'providerName', headerName: "Prestataire", filterPlaceholder: t('common.filter-by-code'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'doctorName', headerName:  t('common.doctor'), filterPlaceholder: t('common.filter-by-doctor'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'date', filterField: 'date', dataType: 'date', headerName: t('common.consultation-date'), filterPlaceholder: t('common.filter-by-consultation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateConsultationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: t('common.status'),  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'isHospitalisation', headerName:  t('statuses.100'), dataType: 'boolean', bodyClassName:"text-center", filterPlaceholder:  t('common.hospi-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: statusHospiBodyTemplate, filterElement: statusHospiFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENCONSULTADMIN') && canDo('MENCONSULTADMIN', 'VIEWCONSULTADMIN') && rowData.dossierConsultation && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={e => viewConsultation(rowData)}
            >
                <i className="fa fa-eye"></i>
            </button>}
        </div>
    };
    
    const viewConsultation = (consultation) => {
        props.history.push('/consultation-details/'+consultation.dossierConsultation.id);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.consultations.map(consultation => {
            return consultation.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusHospiFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                   {t('common.with-hospi')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateConsultationBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusHospiBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isHospitalisation, 'text-red-500 pi-times-circle': !rowData.isHospitalisation })}></i>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.pending:
                return 'primary';
            
            case STATUSES.expired:
                return 'danger';

            case STATUSES.exectuted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENCONSULTADMIN') && canDo('MENCONSULTADMIN', 'LISTCONSULTADMIN')){
            initFilters();
            dispatch(startLoader());
            dispatch(getConsultationsAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>

            <div className="row">
                <div className="col-xl-12">
                    <DataTableBase 
                        data={props.consultations.map(facture => {
                            facture.date = new Date(facture.date);
                            return facture;
                        })}
                        /*data={props.consultations.map(consultation => {
                            consultation.date = new Date(consultation.consultationBon.date);
                            consultation.beneficiaryName = consultation.consultationBon.beneficiaryName;
                            consultation.doctorName = consultation.consultationBon.doctorName;
                            consultation.isHospitalisation = consultation.consultationBon.isHospitalisation;
                            consultation.status = consultation.consultationBon.status;
                            return consultation;
                        })}*/
                        emptyMessage={t('common.no-consult')} 
                        filters={filters}
                        globalFiltersFields={['beneficiaryName', 'doctorName', 'date', 'isHospitalisation', 'status', 'providerName']}
                        header={renderHeader()}
                        columns={setColumns()}
                        rows={10} 
                        loading={props.showLoading}
                        size={'small'}
                        exportFilename={"consultations"}
                        exportColumns={[
                            { title: t('common.patient'), dataKey: 'beneficiaryName' },
                            { title: t('common.doctor'), dataKey: 'doctorName' },
                            { title: "Prestataire", dataKey: 'providerName' },
                            { title: t('common.consultation-date'), dataKey: 'date' },
                            { title: t('common.hospitalised'), dataKey: 'isHospitalisation' },
                            { title: t('common.status'), dataKey: 'status' },
                        ]}
                        showExport={true}
                    />
                </div>
            </div>
            </>
            }
        </> 
    )
};
/* --- END ConsultationList */

const mapStateToProps = (state) => {
    return {
        consultations: state.consultation.consultations,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ConsultationList));